import React from "react";
import withRoleRequired from '../../lib/withRoleRequired';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import IndexCard from "../../components/IndexCard";

const DeliberativeSurvey: React.FunctionComponent = () => (
  <Layout pageHeader="Attitudes Towards Defence"
    moreInfo = "deliberative-survey/index"
  >
    <SEO
      keywords={[`aleph`, `dstl`, `future`, `workforce`, `survey`, `data`, `library`]}
      title="Attitudes Towards Defence"
    />
    <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4">
      <div className="mb-8">
        This section presents the findings from a deliberative survey led by the Institute of Employment Studies. Young adults, parents of young people and careers advisors were questioned about their inclination to work for Defence under a range of different circumstances. Click on the options below to explore the results.
      </div>

      <section>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <IndexCard
            imageSource="/image/deliberative-survey/summary.svg"
            link="/deliberative-survey/summary-data"
            title="Summary Data"
            description="Presents background information about the make-up of the survey participants."
          />
          <IndexCard
            imageSource="/image/deliberative-survey/high-level.svg"
            link="/deliberative-survey/high-level-findings"
            title="High Level Findings"
            description="Highlights the key findings of the study as high level qualitative statements."
          />
          <IndexCard
            imageSource="/image/deliberative-survey/interest.svg"
            link="/deliberative-survey/interest-in-defence"
            title="Interest in Defence"
            description="Shows the most popular reasons why people said they would or would not consider working for Defence."
          />
          <IndexCard
            imageSource="/image/deliberative-survey/impact.svg"
            link="/deliberative-survey/impact-future-factors"
            title="Impact of Future Factors"
            description="Explores the impact of different future factors on people’s stated willingness to work for Defence."
          />
        </div>

      </section>
    </div>


  </Layout>
)

export default withRoleRequired(DeliberativeSurvey);
